import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'blood-pressure-graph--info': true,
        'blood-pressure-graph--info--ru': locale === 'ru'
    });
    return (
        <div className="blood-pressure">
            <div className="blood-pressure-header__text">
                <FormattedMessage id="blood.pressure.header" />
            </div>
            <div className="blood-pressure-graph">
                <div 
                    className={classes}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.pressure.graph.info'})}}
                />
                <div className="blood-pressure-graph--legend">
                    <FormattedMessage id="blood.pressure.graph.hyper" />
                </div>
                <picture>
                    <source 
                        media="(max-width: 600px)"
                        srcSet="/static/svg/blood-pressure-graph__img--mobile.svg"
                    />
                    <img 
                        className="blood-pressure-graph__img"
                        alt=""
                        src="/static/svg/blood-pressure-graph__img.svg"
                    />
                </picture>
                <div className="blood-pressure-graph--text blood-pressure-graph--men">
                    <FormattedMessage id="blood.pressure.graph.m" />
                </div>
                <div className="blood-pressure-graph--text blood-pressure-graph--women">
                    <FormattedMessage id="blood.pressure.graph.n" />
                </div>
                <div 
                    className="blood-pressure-graph--footer"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.pressure.graph.footer'})}}
                />
            </div>
        </div>
    );
})
