import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="french-paradox" id="french-paradox">
        <div className="french-paradox-header__text">
            <FormattedMessage id="french.paradox.header" />
        </div>
        <div className="french-paradox-content">
            <div className="french-paradox-content__item">
                <img
                    src="/static/svg/french-paradox-point.svg"
                />
                <div className="french-paradox-content__text">
                    <FormattedMessage id="french.paradox.item.1" />
                </div>
            </div>
            <div className="french-paradox-content__item">
                <img
                    src="/static/svg/french-paradox-point.svg"
                />
                <div 
                    className="french-paradox-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'french.paradox.item.2'})}}
                />
            </div>
            <div className="french-paradox-content__item">
                <img
                    src="/static/svg/french-paradox-point.svg"
                />
                <div className="french-paradox-content__text">
                    <FormattedMessage id="french.paradox.item.3" />
                </div>
            </div>
        </div>
        <picture>
            <source 
                media="(max-width: 375px)"
                srcSet="/static/images/french-paradox-img--375.png"
            />
            <img 
                className="french-paradox__image"
                alt=""
                src="/static/images/french-paradox-img.png"
            />
        </picture>
    </div>
));
