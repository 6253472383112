import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({logo, header, children, large, small}) => {
    const classes = cx({
        'protect-heart-item': true,
        'protect-heart-item--large': large,
        'protect-heart-item--small': small
    });
    return(
        <div className={classes}>
            <img
                src={logo}
            />
            <div>
                {header}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}