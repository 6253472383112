import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import ProtectHeart from './components/sections/protect-heart';
import HeartFacts from './components/sections/heart-facts';
import Hypertension from './components/sections/hypertension';
import BloodPressureHelp from './components/sections/blood-pressure-help';
import RiskFactors from './components/sections/risk-factors';
import BloodPressure from './components/sections/blood-pressure';
import HighBloodPressure from './components/sections/high-blood-pressure';
import DoctorsRecommend from './components/sections/doctors-recommend';
import MediterraneanDiet from './components/sections/mediterranean-diet';
import FrenchParadox from './components/sections/french-paradox';
import MiddleBuyNow from './components/sections/middle-buy-now';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const { locale } = this.props;
        return (
            <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale} />
                    <TopBanner locale={locale} />
                    <ProtectHeart/>
                    <HeartFacts/>
                    <Hypertension locale={locale} />
                    <BloodPressureHelp locale={locale} />
                    <MiddleBuyNow/>
                    <RiskFactors locale={locale} />
                    <BloodPressure locale={locale} />
                    <HighBloodPressure locale={locale} />
                    <DoctorsRecommend locale={locale} />
                    <MediterraneanDiet/>
                    <FrenchParadox/>
                    {/* <Specialists/> */}
                    <Share/>
                    <Footer/>
                </div>
            </IntlProvider>
        );
    }
}

export default Page;
