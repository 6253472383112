import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, children, id, locale, headache, tired, palpitations, balance}) => {
        const classes = cx({
                'blood-pressure-help-item': true,
                'blood-pressure-help-headache': headache,
                'blood-pressure-help-headache--ru': headache && locale === 'ru',
                'blood-pressure-help-tired': tired,
                'blood-pressure-help-palpitations': palpitations,
                'blood-pressure-help-palpitations--ru': palpitations && locale === 'ru',
                'blood-pressure-help-balance': balance

        });
        return (
            <div className={classes} id={id}>
                    <img
                        className="blood-pressure-help-item__img"
                        src={url}
                    />
                    <div className="blood-pressure-help-item__header">
                            {header}
                    </div>
                    <div className="blood-pressure-help-item__text">
                            {children}
                    </div>
            </div>
        );
}
