import React from 'react';
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="mediterranean-diet" id="mediterranean-diet">
        <div className="mediterranean-diet-header__text">
            <FormattedMessage id="mediterranean.diet.header" />
        </div>
        <div className="mediterranean-diet-content">
            <div className="mediterranean-diet-content__item">
                <img
                    src="/static/svg/mediterranean-diet-point.svg"
                />
                <div className="mediterranean-diet-content__text">
                    <FormattedMessage id="mediterranean.diet.item.1" />
                </div>
            </div>
            <div className="mediterranean-diet-content__item">
                <img
                    src="/static/svg/mediterranean-diet-point.svg"
                />
                <div className="mediterranean-diet-content__text">
                    <FormattedMessage id="mediterranean.diet.item.2" />
                </div>
            </div>
            <div className="mediterranean-diet-content__item">
                <img
                    src="/static/svg/mediterranean-diet-point.svg"
                />
                <div className="mediterranean-diet-content__text">
                    <FormattedMessage id="mediterranean.diet.item.3" />
                </div>
            </div>
        </div>
        <picture>
            <source 
                media="(max-width: 375px)"
                srcSet="/static/images/mediterranean-diet-banner--375.png"
            />
            <img 
                className="mediterranean-diet-header__image"
                alt=""
                src="/static/images/mediterranean-diet-banner.png"
            />
        </picture>
    </div>
);
