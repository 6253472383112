import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="protect-heart">
        <HeaderText/>
        <div className="protect-heart-items">
            <Item
                large
                logo="/static/svg/protect-heart-heart.svg"
                header={<FormattedMessage id="protect.heart.item.header.left" />}
            >
                <div className="protect-heart-item-left-text">                
                    <FormattedMessage id="protect.heart.item.left" />
                </div>
            </Item>
            <Item
                large
                logo="/static/svg/protect-heart-stress.svg"
                header={<FormattedMessage id="protect.heart.item.header.center" />}
            >
                <div className="protect-heart-item-center-text">
                    <FormattedMessage id="protect.heart.item.center" />
                </div>
            </Item>
            <Item
                small
                logo="/static/svg/protect-heart-pill.svg"
                header={<FormattedMessage id="protect.heart.item.header.right" />}
            >
                <div className="protect-heart-item-right-text">
                    <FormattedMessage id="protect.heart.item.right" />
                </div>
            </Item>
        </div>
    </div>
);