import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({url, header, children, db, top, bottom, blood, locale, sm}) => {
        const classes = cx({
                'risk-factors-item': true,
                'risk-factors-item--sm': sm,
                'risk-factors-item--db': db,
                'risk-factors-item--top': top,
                'risk-factors-item--bottom': bottom,
                'risk-factors-item--bottom--ru': blood === 'ru',
                'risk-factors-item--ru': locale === 'ru'
        });
        return (
            <div className={classes}>
                    <img
                        className="risk-factors-item__img"
                        src={url}
                    />
                    <div className="risk-factors-item__header">
                            {header}
                    </div>
                    <div className="risk-factors-item__text">
                            {children}
                    </div>
            </div>
        );
}
