import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'hypertension__wrap': true,
        'hypertension__wrap--ru': locale === 'ru'
    });
    return (
        <div className="hypertension" id="hypertension">
            <div className={classes}>
                <div 
                    className="hypertension-header__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hypertension.header'})}}
                />
                <div className="hypertension-content">
                    <div className="hypertension-content__item">
                        <img
                            src="/static/svg/hypertension-point.svg"
                        />
                        <div 
                            className="hypertension-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hypertension.item.1'})}}
                        />
                    </div>
                    <div className="hypertension-content__item">
                        <img
                            src="/static/svg/hypertension-point.svg"
                        />
                        <div 
                            className="hypertension-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hypertension.item.2'})}}
                        />
                    </div>
                    <div className="hypertension-content__item">
                        <img
                            src="/static/svg/hypertension-point.svg"
                        />
                        <div 
                            className="hypertension-content__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hypertension.item.3'})}}
                        />
                    </div>
                </div>
            </div>
            <div className="hypertension-footer">
                <div className="hypertension-footer__header">
                    <FormattedMessage id="hypertension.footer.head" />
                </div>
                <div className="hypertension-footer__items">
                    <div 
                        className="hypertension-footer_item"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hypertension.footer.1'})}}
                    />
                    <div 
                        className="hypertension-footer_item"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hypertension.footer.2'})}}
                    />
                    <div 
                        className="hypertension-footer_item"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'hypertension.footer.3'})}}
                    />
                </div>
            </div>
        </div>
    );
})
