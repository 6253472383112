import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const stroke = cx({
        'high-blood-pressure-graph--text': true,
        'high-blood-pressure-graph--stroke': true,
        'high-blood-pressure-graph--text--ru': locale === 'ru'
    });
    const vision = cx({
        'high-blood-pressure-graph--text': true,
        'high-blood-pressure-graph--vision': true,
        'high-blood-pressure-graph--text--ru': locale === 'ru'
    });
    const heart = cx({
        'high-blood-pressure-graph--text': true,
        'high-blood-pressure-graph--heart': true,
        'high-blood-pressure-graph--text--ru': locale === 'ru'
    });
    const attack = cx({
        'high-blood-pressure-graph--text': true,
        'high-blood-pressure-graph--attack': true,
        'high-blood-pressure-graph--text--ru': locale === 'ru'
    });
    const kidney = cx({
        'high-blood-pressure-graph--text': true,
        'high-blood-pressure-graph--kidney': true,
        'high-blood-pressure-graph--text--ru': locale === 'ru'
    });
    const erectile = cx({
        'high-blood-pressure-graph--text': true,
        'high-blood-pressure-graph--erectile': true,
        'high-blood-pressure-graph--text--ru': locale === 'ru'
    });
    const footer = cx({
        'high-blood-pressure-graph--footer': true,
        'high-blood-pressure-graph--footer--ru': locale === 'ru'
    });
    const info = cx({
        'high-blood-pressure-graph--info': true,
        'high-blood-pressure-graph--info--ru': locale === 'ru'
    });
    return (
        <div className="high-blood-pressure">
            <div className="high-blood-pressure-header__text">
                <FormattedMessage id="high.blood.pressure.header" />
            </div>
            <div className="high-blood-pressure-graph">
                <div 
                    className={info}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'high.blood.pressure.info'})}}
                />
                <picture>
                    <source 
                        media="(max-width: 600px)"
                        srcSet="/static/svg/high-blood-pressure-graph__img--mobile.svg"
                    />
                    <img 
                        className="high-blood-pressure-graph__img"
                        alt=""
                        src="/static/svg/high-blood-pressure-graph__img.svg"
                    />
                </picture>
                <div className={stroke}>
                    <FormattedMessage id="high.blood.pressure.stroke" />
                </div>
                <div className={vision}>
                    <FormattedMessage id="high.blood.pressure.vision" />
                </div>
                <div className={heart}>
                    <FormattedMessage id="high.blood.pressure.heart" />
                </div>
                <div className={attack}>
                    <FormattedMessage id="high.blood.pressure.attack" />
                </div>
                <div className={kidney}>
                    <FormattedMessage id="high.blood.pressure.kidney" />
                </div>
                <div className={erectile}>
                    <FormattedMessage id="high.blood.pressure.erectile" />
                </div>
                <div 
                    className={footer}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'high.blood.pressure.footer'})}}
                />
            </div>
        </div>
    );
})
