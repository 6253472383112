import React from 'react';
import Item from './Item';
import cx from 'classnames';
import './index.css';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'blood-pressure-help__vessel': true,
        'blood-pressure-help__vessel--normal': true,
        'blood-pressure-help__vessel--normal--ru': locale === 'ru'
    });
    const classes_header = cx({
        'blood-pressure-help__header': true,
        'blood-pressure-help__header--ru': locale === 'ru'
    });
    return(
        <div className="blood-pressure-help" id="blood-pressure-help">
            <div className={classes_header}>
                <FormattedMessage id="blood.pressure.help.header" />
            </div>
            <div className="blood-pressure-help__items">
                <div className="blood-pressure-help__items--left">
                    <Item
                        id="blood-pressure-help-headache"
                        headache
                        locale = {locale}
                        url="/static/svg/blood-pressure-help-headache.svg"
                    >
                        <div>
                            <FormattedMessage id="blood.pressure.help.item.1" />
                        </div>
                    </Item>
                    <Item
                        id="blood-pressure-help-tired"
                        tired
                        url="/static/svg/blood-pressure-help-tired.svg"
                    >
                        <div>
                            <FormattedMessage id="blood.pressure.help.item.3" />
                        </div>
                    </Item>
                </div>
                <div className="blood-pressure-help__items--middle">
                    <picture>
                        <source 
                            media="(max-width: 700px)"
                            srcSet="/static/svg/blood-pressure-help-img--mobile.svg"
                        />
                        <img src="/static/svg/blood-pressure-help-img.svg" alt="" />
                    </picture>              
                    <div className={classes}>
                        <FormattedMessage id="blood.pressure.help.normal" />
                    </div>
                    <div className="blood-pressure-help__vessel blood-pressure-help__vessel--pre">
                        <FormattedMessage id="blood.pressure.help.pre" />
                    </div>
                    <div className="blood-pressure-help__vessel blood-pressure-help__vessel--hyper">
                        <FormattedMessage id="blood.pressure.help.hyper" />
                    </div>
                </div>
                <div className="blood-pressure-help__items--right">
                    <Item
                        id="blood-pressure-help-palpitations"
                        locale = {locale}
                        palpitations
                        url="/static/svg/blood-pressure-help-palpitations.svg"
                        >
                        <div>
                            <FormattedMessage id="blood.pressure.help.item.2" />
                        </div>
                    </Item>
                    <Item
                        id="blood-pressure-help-balance"
                        balance
                        url="/static/svg/blood-pressure-help-balance.svg"
                    >
                        <div>
                            <FormattedMessage id="blood.pressure.help.item.4" />
                        </div>
                    </Item>
                </div>
                <div className="blood-pressure-help__link">
                    <a href="https://www.haigekassa.ee/blogi/kuidas-alandada-korgenenud-vererohku" target="_blank">
                        <FormattedMessage id="blood.pressure.help.link" />
                    </a>
                </div>
            </div>
        </div>
    );
}
