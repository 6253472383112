import React from 'react';
import './index.css';
import BuyNowButton from '../../common/buy-now-button'
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const header = cx({
        'doctors-recommend-header__text': true,
        'doctors-recommend-header__text--ru': locale === 'ru'
    });
    const middle = cx({
        'doctors-recommend-graph--middle': true,
        'doctors-recommend-graph--middle--ru': locale === 'ru'
    });
    return (
        <div className="doctors-recommend">
            <div className={header}>
                <FormattedMessage id="doctors.recommend.header" />
            </div>
            <div className="doctors-recommend-wrap">
                <div className="doctors-recommend-buy">
                    <picture>
                        <source 
                            media="(max-width: 375px)"
                            srcSet="/static/images/doctors-recommend-buy__img--mobile.png"
                        />
                        <img 
                            className="doctors-recommend-buy__img"
                            alt=""
                            src="/static/images/doctors-recommend-buy__img.png"
                        />
                    </picture>
                    <span className="doctors-recommend__button">
                        <BuyNowButton />
                    </span>
                </div>
                <div className="doctors-recommend-graph">
                    <picture>
                        <source 
                            media="(max-width: 375px)"
                            srcSet="/static/svg/doctors-recommend-graph__img--mobile.svg"
                        />
                        <img 
                            className="doctors-recommend-graph__img"
                            alt=""
                            src="/static/svg/doctors-recommend-graph__img.svg"
                        />
                    </picture>                    
                    <div className={middle}>
                        <FormattedMessage id="doctors.recommend.middle" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--exercise">
                        <FormattedMessage id="doctors.recommend.exercise" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--salt">
                        <FormattedMessage id="doctors.recommend.salt" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--potassium">
                        <FormattedMessage id="doctors.recommend.potassium" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--alcohol">
                        <FormattedMessage id="doctors.recommend.alcohol" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--fruit">
                        <FormattedMessage id="doctors.recommend.fruit" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--smoke">
                        <FormattedMessage id="doctors.recommend.smoke" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--fats">
                        <FormattedMessage id="doctors.recommend.fats" />
                    </div>
                    <div className="doctors-recommend-graph--text doctors-recommend-graph--weight">
                        <FormattedMessage id="doctors.recommend.weight" />
                    </div>
                    <div 
                        className="doctors-recommend-graph--text doctors-recommend-graph--fish"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'doctors.recommend.fish'})}}
                    />
                </div>
            </div>
        </div>
    );
})
