import React from 'react';
import Item from './Item';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => (
    <div className="risk-factors" id="risk-factors">
        <div className="risk-factors__header">
            <FormattedMessage id="risk.factors.header" />
        </div>
        <div className="risk-factors__items">
            <div className="risk-factors__items--top">
                <Item
                    url="/static/svg/risk-factors-dna.svg"
                >
                    <div>
                        <FormattedMessage id="risk.factors.item.1" />
                    </div>
                </Item>
                <Item
                    top
                    url="/static/svg/risk-factors-weight.svg"
                    >
                    <div>
                        <FormattedMessage id="risk.factors.item.2" />
                    </div>
                </Item>
                <Item
                    url="/static/svg/risk-factors-cholesterol.svg"
                >
                    <div>
                        <FormattedMessage id="risk.factors.item.3" />
                    </div>
                </Item>
            </div>
            <div className="risk-factors__items--top-middle">
                <Item
                    url="/static/svg/risk-factors-stress.svg"
                >
                    <div>
                        <FormattedMessage id="risk.factors.item.4" />
                    </div>
                </Item>
                <Item
                    sm
                    url="/static/svg/risk-factors-diet.svg"
                >
                    <div>
                        <FormattedMessage id="risk.factors.item.5" />
                    </div>
                </Item>
            </div>
            <div className="risk-factors__items--bottom-middle">
                <Item
                    url="/static/svg/risk-factors-age.svg"
                    >
                    <div
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'risk.factors.item.6'})}}
                    />
                </Item>
                <Item
                    locale={locale}
                    url="/static/svg/risk-factors-activity.svg"
                >
                    <div>
                        <FormattedMessage id="risk.factors.item.7" />
                    </div>
                </Item>
            </div>
            <div className="risk-factors__items--bottom">
                <Item
                    url="/static/svg/risk-factors-smoke.svg"
                    >
                    <div>
                        <FormattedMessage id="risk.factors.item.8" />
                    </div>
                </Item>
                <Item
                    blood={locale}
                    bottom
                    url="/static/svg/risk-factors-blood.svg"
                    >
                    <div>
                        <FormattedMessage id="risk.factors.item.9" />
                    </div>
                </Item>
                <Item
                    db
                    url="/static/svg/risk-factors-diabetes.svg"
                >
                    <div>
                        <FormattedMessage id="risk.factors.item.10" />
                    </div>
                </Item>
            </div>
        </div>
    </div>
));
